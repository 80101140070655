require('../images/avatar.jpg');

import '../scss/themes/inspinia/main.scss';

import 'bootstrap/dist/js/bootstrap';
import toastr from 'toastr/toastr';
import swal from 'sweetalert';
import * as Pace from "pace-js";
import 'metismenu/src/index';
import 'jquery-slimscroll/jquery.slimscroll';
import 'datatables.net-bs4/js/dataTables.bootstrap4';
import 'chart.js/dist/Chart'
import '../inspinia/js/inspinia';
import 'icheck/icheck';
import 'select2/dist/js/select2.full';
import flatpickr from 'flatpickr';
import {Polish} from 'flatpickr/dist/l10n/pl.js';
import datatable_locale from './datatable_locale'

global.toastr = toastr;
global.swal = swal;

$(document).ajaxStart(() => Pace.restart());

$(document).ready(function () {
    $('.i-checks').iCheck({
        checkboxClass: 'icheckbox_square-green',
        radioClass: 'iradio_square-green',
    });

    $('select.select2').select2();
    flatpickr('.date-time-picker', {
        enableTime: true,
        time_24hr: true,
        dateFormat: "Y-m-d H:i",
        locale: Polish
    });
});

function getLocale() {
    const cookieArr = document.cookie.split(";");

    for (let i = 0; i < cookieArr.length; i++) {
        const cookiePair = cookieArr[i].split("=");
        if ('_locale' === cookiePair[0].trim()) {
            return decodeURIComponent(cookiePair[1]);
        }
    }
    return 'pl';
}

const locale = getLocale();

$.extend(true, $.fn.dataTable.defaults, {

    searching: true,
    dom: "rt<'row'<'col-sm-6'i><'col-sm-6'p>>",
    pageLength: 10,
    responsive: true,
    serverSide: true,
    saveState: true,
    autoWidth: false,
    order: [
        [0, 'asc']
    ],
    language: datatable_locale[locale],
    "createdRow": (row, data) => {
        if (data.hasOwnProperty('rowClass')) {
            $(row).addClass(data.rowClass);
        }
    },
    "preDrawCallback": function (settings) {
        $(this).parents('.ibox-content').first().addClass('sk-loading');
    },
    "drawCallback": function (settings) {
        settings.nTable.dispatchEvent(new CustomEvent('onDrawCallback' ,{
            detail: {
                datatable: this.DataTable()
            }
        }));
        $(this).parents('.ibox-content').first().removeClass('sk-loading');
        $(this).find('.i-checks').iCheck({
            checkboxClass: 'icheckbox_square-green',
            radioClass: 'iradio_square-green',
        });
    }
});

const search = e => {
    const input$ = $(e.target);
    const form$ = input$.parents('form').first();
    const dTable = $('#' + form$.attr('filter-for')).DataTable();
    dTable.draw();
    window.history.replaceState({}, "", "?" + form$.serialize());
};

$(document).ready(() => {
    // text input filtering
    const TYPING_DELAY = 500;

    $('input', '.table_filter')
        .on('input', e => {
            if (e.target.typingTimeout !== undefined) {
                clearTimeout(e.target.typingTimeout);
            }

            e.target.typingTimeout = setTimeout(() => {
                search(e);
            }, TYPING_DELAY);
        })
        .on('keypress', e => {
            // search on ENTER
            if (e.keyCode == 13) {
                if (e.target.typingTimeout !== undefined) {
                    clearTimeout(e.target.typingTimeout);
                }

                search(e);
            }
        })
        .on('ifToggled', e => {
            search(e);
        });

    $('select', '.table_filter').on('change', search);

    $('.data_table').each((i, el) => {
        $.ajax({
            url: $(el).data('config'),
            success: res => {
                res['ajax']['data'] = function (d) {
                    let id = $(el).attr('id');
                    let form$ = $('form[filter-for=' + id + ']');
                    if (form$) {
                        d.filter = form$.serialize();
                    }
                };
                $(el).DataTable(res);
            }
        })
    });


    $('.navbar-minimalize').on('click', function (e) {
        document.cookie = "collapseSidebar=" + ($('body').hasClass('mini-navbar') ? 1 : 0) + "; max-age=31536000; path=/; secure";
    });

    $('[data-toggle="tooltip"]').tooltip();

    $('canvas.chart').each((i, el) => {
        $.ajax({
            url: $(el).data('config'),
            success: res => {
                new Chart(el.getContext("2d"), res);
            }
        })
    });

    $('.custom-file-input').on('change', function () {
        let fileName = $(this).val().split('\\').pop();
        $(this).next('.custom-file-label').addClass("selected").html(fileName);
    });

    $('#language_change').click(e => {
        let locale = $(e.currentTarget).data('lang');
        document.cookie = "_locale=" + locale + "; max-age=31536000; path=/; secure;";
        location.reload();
    });

});
